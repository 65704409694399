<script>
import { required, email } from 'vuelidate/lib/validators';
import authMixin from '@shared/mixins/auth';
import APIUser from '@app/services/API/User';

export default {
  mixins: [authMixin],
  data() {
    return {
      email: '',
      password: '',
      isLoading: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  methods: {
    handle() {
      const { email, password } = this;
      this.isLoading = true;
      this.$store.dispatch('auth/login', { email, password })
        .catch((error) => this.handleAuthError(error, APIUser, email))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <section class="form p-5">
    <form novalidate @submit.prevent="dataIsValid(isLoading) && handle()">
      <h1 class="title is-4">
        Connexion
      </h1>

      <b-field
        label="Adresse email"
        v-bind="$getErrorProps($v.email, ['required', 'email'])"
      >
        <b-input v-model="email" type="email" />
      </b-field>

      <b-field
        label="Mot de passe"
        v-bind="$getErrorProps($v.password, ['required'])"
      >
        <b-input v-model="password" type="password" password-reveal />
      </b-field>

      <b-field>
        <b-button type="is-primary" native-type="submit" :loading="isLoading" expanded>
          Se connecter
        </b-button>
      </b-field>

      <b-field class="has-text-centered">
        <b-button type="is-text" @click="$emit('switch')">
          Mot de passe oublié
        </b-button>
      </b-field>
    </form>

    <hr>

    <p>
      Pas encore de compte ?
      <router-link class="tdecoration-underline" to="/inscription">
        Inscrivez-vous gratuitement
      </router-link>
    </p>
  </section>
</template>
